<template>
  <article class="group col-span-full flex md:col-span-2 lg:col-span-1">
    <NuxtLink
      :to="card.link"
      :rel="isExternalLink(card.link) ? 'external' : undefined"
      class="flex flex-row lg:flex-col lg:gap-5"
    >
      <figure class="aspect-square max-w-40 flex-shrink-0 lg:max-w-none">
        <NuxtImg
          :src="card.image?.filename"
          :alt="card.image?.alt"
          sizes="160px md:576px"
          loading="lazy"
          class="ease-out-expo size-full rounded-lg object-cover transition-transform duration-700 group-hover:lg:-translate-y-2"
        />
      </figure>

      <div class="flex flex-col gap-3 px-5 py-4">
        <h3 class="text-[1.125rem] font-medium">{{ card.title }}</h3>
        <p
          v-if="card.description"
          class="text-secondary hidden text-sm leading-normal lg:block"
        >
          {{ truncate(card.description, 80) }}
        </p>
      </div>
    </NuxtLink>
  </article>
</template>

<script setup lang="ts">
import type { CardListCard } from '@/components/card/CardList.vue'
import { isExternalLink } from '@/utils/link-utils'
import { truncate } from '@/utils/common-utils'

defineProps<{
  card: CardListCard
}>()
</script>
